import React from 'react';

import { BlogRollPage } from '@kali/theme/lib/pages/blog/roll/page';
import Layout from '../components/layout';

export default function BlogRoute() {
  return (
    <Layout title="Blog">
      <BlogRollPage items={[]} />
    </Layout>
  );
}
